/* eslint-disable no-unused-vars */
<template>
  <div>
    <!-- breadCrumb -->
    <v-breadcrumbs :items="breadcrumbList" divider="/">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :to="item.href" :disabled="item.disabled">
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <!-- breadCrumb -->
    <v-row>
      <v-col xs="6" md="6">
        <p class="main-color main-header">{{ $t("Applicant Name") }}</p>
      </v-col>
      <v-col xs="6" md="6" class="edit-icon" v-if="!editable">
        <v-icon
          v-if="approveBtnVisible"
          class="mr-2"
          big
          :disabled="approveDisabledBtn"
          @click="approveDialogOpen"
        >
          check_circle_outline
        </v-icon>
        <v-icon v-if="editBtnVisible" @click="editMode">edit</v-icon>

        <!-- Button trigger modal -->
      </v-col>
    </v-row>
    <v-form v-model="valid" ref="form" class="my-5">
      <v-row>
        <v-col cols="12" sm="12" class="first-info">
          <v-card class="mx-auto py-5" shaped>
            <v-card-text>
              <v-row class="lighten-5" style="" justify="center">
                <v-col cols="12" style="padding-top: 0; padding-bottom: 0">
                  <v-row class="py-3">
                    <v-col cols="12">
                      <h3 id="info-header" class="info-header">
                        {{ $t("General Information") }}
                      </h3>
                    </v-col>
                    <v-col cols="12" sm="4" class="text-center" id="logo-div">
                      <div class="logo-div-img">
                        <img
                          id="logo"
                          class="rounded-circle"
                          src="../../assets/avatar.png"
                          alt=""
                        />
                      </div>
                    </v-col>
                    <v-col cols="12" sm="8" id="info-div" class="info-div">
                      <!-- ====================================== show data view==================================== -->
                      <div class="viewData" v-if="!editable">
                        <v-row>
                          <v-col xs="4" md="4">
                            <p>
                              <strong> {{ $t("Application Number") }}:</strong>
                            </p>
                          </v-col>
                          <v-col xs="8" md="8" class="">
                            <p class="value">
                              {{ admission_show.application_number }}
                            </p>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col xs="4" md="4">
                            <p>
                              <strong> {{ $t("Applicant Name") }}:</strong>
                            </p>
                          </v-col>
                          <v-col xs="8" md="8" class="">
                            <p class="value">
                              {{ admission_show.en.student_first_name }}
                              {{ admission_show.en.student_father_name }}
                              {{ admission_show.en.student_grand_father_name }}
                              {{ admission_show.en.student_family_name }}
                            </p>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col xs="4" md="4">
                            <p><strong></strong></p>
                          </v-col>
                          <v-col xs="8" md="8" class="">
                            <p class="value">
                              {{ admission_show.ar.student_first_name }}
                              {{ admission_show.ar.student_father_name }}
                              {{ admission_show.ar.student_grand_father_name }}
                              {{ admission_show.ar.student_family_name }}
                            </p>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col xs="4" md="4">
                            <p>
                              <strong>
                                {{
                                  $t("Applicant Birthdate Geogerion")
                                }}:</strong
                              >
                            </p>
                          </v-col>
                          <v-col xs="8" md="8" class="">
                            <p class="value">
                              {{ admission_show.student_birthdate_m }}

                              <v-icon
                                v-if="admission_show.in_range"
                                :title="admission_show.date_in_range_title"
                                class="green--text"
                                >fiber_manual_record</v-icon
                              >
                              <v-icon
                                v-if="!admission_show.in_range"
                                :title="admission_show.date_in_range_title"
                                class="red--text"
                                >fiber_manual_record</v-icon
                              >
                            </p>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col xs="4" md="4">
                            <p>
                              <strong>
                                {{ $t("Applicant Birthdate Hijri") }}:</strong
                              >
                            </p>
                          </v-col>
                          <v-col xs="8" md="8" class="">
                            <p class="value">
                              {{ admission_show.student_birthdate_h }}
                              <v-icon
                                v-if="admission_show.in_range"
                                :title="admission_show.date_in_range_title"
                                class="green--text"
                                >fiber_manual_record</v-icon
                              >
                              <v-icon
                                v-if="!admission_show.in_range"
                                :title="admission_show.date_in_range_title"
                                class="red--text"
                                >fiber_manual_record</v-icon
                              >
                            </p>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col xs="4" md="4">
                            <p>
                              <strong
                                >{{ $t("Applicant Place Of Birth") }}:
                              </strong>
                            </p>
                          </v-col>
                          <v-col xs="8" md="8" class="">
                            <p class="value">
                              {{ admission_show.student_place_of_birth }}
                            </p>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col xs="4" md="4">
                            <p>
                              <strong>{{ $t("Applicant Gender") }}: </strong>
                            </p>
                          </v-col>
                          <v-col xs="8" md="8" class="">
                            <p class="value">
                              {{
                                admission_show.student_gender
                                  .charAt(0)
                                  .toUpperCase() +
                                admission_show.student_gender.slice(1)
                              }}
                            </p>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col xs="4" md="4">
                            <p>
                              <strong>
                                {{ $t("Applicant Nationality") }}:</strong
                              >
                            </p>
                          </v-col>
                          <v-col xs="8" md="8" class="">
                            <p class="value">
                              {{ admission_show.student_nationality_id }}
                            </p>
                          </v-col>
                        </v-row>

                        <v-row v-if="!editable && saudi">
                          <v-col xs="4" md="4">
                            <p>
                              <strong> {{ $t("National ID") }}:</strong>
                            </p>
                          </v-col>
                          <v-col xs="8" class="" md="8">
                            <p class="value">
                              {{ admission_show.student_national_id }}
                            </p>
                          </v-col>
                        </v-row>

                        <v-row v-if="!editable && !saudi">
                          <v-col xs="4" md="4">
                            <p>
                              <strong> {{ $t("Passport Number") }}:</strong>
                            </p>
                          </v-col>
                          <v-col xs="8" class="" md="8">
                            <p class="value">
                              {{ admission_show.student_passport_no }}
                            </p>
                          </v-col>
                        </v-row>

                        <v-row v-if="!editable" :class="{ hidden: saudi }">
                          <v-col xs="4" md="4">
                            <p>
                              <strong> {{ $t("Iqama No") }}:</strong>
                            </p>
                          </v-col>
                          <v-col xs="8" class="" md="8">
                            <p class="value">
                              {{ admission_show.student_iqama_no }}
                            </p>
                          </v-col>
                        </v-row>

                        <v-row v-if="!editable" :class="{ hidden: saudi }">
                          <v-col xs="4" md="4">
                            <p>
                              <strong>
                                {{ $t("Iqama No Expiry Date") }}:</strong
                              >
                            </p>
                          </v-col>
                          <v-col xs="8" class="" md="8">
                            <p class="value">
                              {{ admission_show.student_iqama_no_expire_date }}
                              <v-icon
                                v-if="admission_show.iqama_class == 'green'"
                                :title="admission_show.iqama_class_title"
                                class="green--text"
                                >fiber_manual_record</v-icon
                              >
                              <v-icon
                                v-if="admission_show.iqama_class == 'red'"
                                :title="admission_show.iqama_class_title"
                                class="red--text"
                                >fiber_manual_record</v-icon
                              >

                              <v-icon
                                v-if="admission_show.iqama_class == 'yellow'"
                                :title="admission_show.iqama_class_title"
                                class="yellow--text"
                                >fiber_manual_record</v-icon
                              >
                            </p>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col xs="4" md="4">
                            <p>
                              <strong> {{ $t("Applicant Religion") }}:</strong>
                            </p>
                          </v-col>
                          <v-col
                            xs="8"
                            md="8"
                            class=""
                            v-if="admission_show.student_religion"
                          >
                            <p class="value">
                              {{
                                admission_show.student_religion
                                  .charAt(0)
                                  .toUpperCase() +
                                admission_show.student_religion.slice(1)
                              }}
                            </p>
                          </v-col>
                          <v-col
                            xs="8"
                            md="8"
                            class=""
                            v-if="!admission_show.student_religion"
                          >
                            <p class="value">
                              {{ $t("No Data Selected") }}
                            </p>
                          </v-col>
                        </v-row>
                      </div>

                      <!-- ========================================== edit data view ======================================= -->
                      <div class="editData" v-if="editable">
                        <v-row>
                          <v-col xs="4" md="4">
                            <p>
                              <strong>{{ $t("First Name") }}:</strong>
                            </p>
                          </v-col>
                          <v-col xs="8" class="" md="8">
                            <v-text-field
                              @focus="onFocus()"
                              v-model.trim="admission.en.student_first_name"
                              solo
                              dense
                              :rules="[
                                validationRules.required,
                                validationRules.maxLength25,
                                validationRules.spaces,
                              ]"
                            ></v-text-field>

                            <div v-if="validation_errors.en">
                              <p
                                class="error--text"
                                v-for="(error, index) in validation_errors.en
                                  .student_first_name"
                                :key="index"
                              >
                                {{ error }}
                              </p>
                            </div>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col xs="4" md="4">
                            <p>
                              <strong>{{ $t("Father's Name") }}:</strong>
                            </p>
                          </v-col>
                          <v-col xs="8" class="" md="8">
                            <v-text-field
                              @focus="onFocus()"
                              v-model.trim="admission.en.student_father_name"
                              :rules="[validationRules.maxLength25]"
                              solo
                              dense
                            ></v-text-field>

                            <div v-if="validation_errors.en">
                              <p
                                class="error--text"
                                v-for="(error, index) in validation_errors.en
                                  .student_father_name"
                                :key="index"
                              >
                                {{ error }}
                              </p>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col xs="4" md="4">
                            <p>
                              <strong>{{ $t("GrandFather Name") }}:</strong>
                            </p>
                          </v-col>
                          <v-col xs="8" class="" md="8">
                            <v-text-field
                              @focus="onFocus()"
                              v-model.trim="
                                admission.en.student_grand_father_name
                              "
                              :rules="[validationRules.maxLength25]"
                              solo
                              dense
                            ></v-text-field>

                            <div v-if="validation_errors.en">
                              <p
                                class="error--text"
                                v-for="(error, index) in validation_errors.en
                                  .student_grand_father_name"
                                :key="index"
                              >
                                {{ error }}
                              </p>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col xs="4" md="4">
                            <p>
                              <strong>{{ $t("Family Name") }}:</strong>
                            </p>
                          </v-col>
                          <v-col xs="8" class="" md="8">
                            <v-text-field
                              @focus="onFocus()"
                              v-model.trim="admission.en.student_family_name"
                              :rules="[
                                validationRules.required,
                                validationRules.maxLength25,
                                validationRules.spaces,
                              ]"
                              solo
                              dense
                            ></v-text-field>

                            <div v-if="validation_errors.en">
                              <p
                                class="error--text"
                                v-for="(error, index) in validation_errors.en
                                  .student_family_name"
                                :key="index"
                              >
                                {{ error }}
                              </p>
                            </div>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col xs="4" md="4">
                            <p>
                              <strong>{{ "First Name" }}:</strong>
                            </p>
                          </v-col>
                          <v-col xs="8" class="" md="8">
                            <v-text-field
                              @focus="onFocus()"
                              v-model.trim="admission.ar.student_first_name"
                              solo
                              dense
                              :rules="[
                                validationRules.required,
                                validationRules.maxLength25,
                                CheckArabicCharactersOnly(
                                  admission.ar.student_first_name
                                ),
                                validationRules.spaces,
                              ]"
                            ></v-text-field>

                            <div v-if="validation_errors.ar">
                              <p
                                class="error--text"
                                v-for="(error, index) in validation_errors.ar
                                  .student_first_name"
                                :key="index"
                              >
                                {{ error }}
                              </p>
                            </div>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col xs="4" md="4">
                            <p>
                              <strong>{{ $t("Father's Name") }}:</strong>
                            </p>
                          </v-col>
                          <v-col xs="8" class="" md="8">
                            <v-text-field
                              @focus="onFocus()"
                              v-model.trim="admission.ar.student_father_name"
                              solo
                              dense
                              :rules="[
                                validationRules.maxLength25,
                                CheckArabicCharactersOnly(
                                  admission.ar.student_father_name
                                ),
                              ]"
                            ></v-text-field>

                            <div v-if="validation_errors.ar">
                              <p
                                class="error--text"
                                v-for="(error, index) in validation_errors.ar
                                  .student_father_name"
                                :key="index"
                              >
                                {{ error }}
                              </p>
                            </div>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col xs="4" md="4">
                            <p>
                              <strong>{{ $t("GrandFather Name") }}:</strong>
                            </p>
                          </v-col>
                          <v-col xs="8" class="" md="8">
                            <v-text-field
                              @focus="onFocus()"
                              v-model.trim="
                                admission.ar.student_grand_father_name
                              "
                              solo
                              dense
                              :rules="[
                                validationRules.maxLength25,
                                CheckArabicCharactersOnly(
                                  admission.ar.student_grand_father_name
                                ),
                              ]"
                            ></v-text-field>

                            <div v-if="validation_errors.ar">
                              <p
                                class="error--text"
                                v-for="(error, index) in validation_errors.ar
                                  .student_grand_father_name"
                                :key="index"
                              >
                                {{ error }}
                              </p>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col xs="4" md="4">
                            <p>
                              <strong>{{ $t("Family Name") }}:</strong>
                            </p>
                          </v-col>
                          <v-col xs="8" class="" md="8">
                            <v-text-field
                              @focus="onFocus()"
                              v-model.trim="admission.ar.student_family_name"
                              solo
                              dense
                              :rules="[
                                validationRules.required,
                                validationRules.maxLength25,
                                CheckArabicCharactersOnly(
                                  admission.ar.student_family_name
                                ),
                                validationRules.spaces,
                              ]"
                            ></v-text-field>

                            <div v-if="validation_errors.ar">
                              <p
                                class="error--text"
                                v-for="(error, index) in validation_errors.ar
                                  .student_family_name"
                                :key="index"
                              >
                                {{ error }}
                              </p>
                            </div>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col xs="4" md="4">
                            <p>
                              <strong
                                >{{
                                  $t("Applicant Birthdate Geogerion")
                                }}:</strong
                              >
                            </p>
                          </v-col>
                          <v-col xs="8" class="" md="8">
                            <v-text-field
                              id="gregorian"
                              autocomplete="off"
                              class="form-group"
                              @focus="onFocus()"
                              v-model.lazy="admission.student_birthdate_m"
                              :rules="[validationRules.required]"
                              solo
                              dense
                              @keydown.prevent
                              @paste.prevent
                              @drop.prevent
                            ></v-text-field>

                            <div v-if="validation_errors.student_birthdate_m">
                              <p
                                class="error--text"
                                v-for="(
                                  error, index
                                ) in validation_errors.student_birthdate_m"
                                :key="index"
                              >
                                {{ error }}
                              </p>
                            </div>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col xs="4" md="4">
                            <p>
                              <strong
                                >{{ $t("Applicant Birthdate Hijri") }}:</strong
                              >
                            </p>
                          </v-col>
                          <v-col xs="8" class="" md="8">
                            <v-text-field
                              @focus="onFocus()"
                              id="hijri"
                              autocomplete="off"
                              v-model.lazy="admission.student_birthdate_h"
                              :rules="[validationRules.required]"
                              solo
                              dense
                              @keydown.prevent
                              @paste.prevent
                              @drop.prevent
                            ></v-text-field>

                            <div v-if="validation_errors.student_birthdate_h">
                              <p
                                class="error--text"
                                v-for="(
                                  error, index
                                ) in validation_errors.student_birthdate_h"
                                :key="index"
                              >
                                {{ error }}
                              </p>
                            </div>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col xs="4" md="4">
                            <p>
                              <strong
                                >{{ $t("Applicant Place Of Birth") }}:</strong
                              >
                            </p>
                          </v-col>
                          <v-col xs="8" class="" md="8">
                            <v-text-field
                              @focus="onFocus()"
                              autocomplete="off"
                              v-model.trim="admission.student_place_of_birth"
                              :rules="[
                                validationRules.required,
                                validationRules.maxLength50,
                                validationRules.spaces,
                              ]"
                              solo
                              dense
                            ></v-text-field>

                            <div
                              v-if="validation_errors.student_place_of_birth"
                            >
                              <p
                                class="error--text"
                                v-for="(
                                  error, index
                                ) in validation_errors.student_place_of_birth"
                                :key="index"
                              >
                                {{ error }}
                              </p>
                            </div>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col xs="4" md="4">
                            <p>
                              <strong>{{ $t("Applicant Gender") }}:</strong>
                            </p>
                          </v-col>
                          <v-col xs="8" class="" md="8">
                            <div class="radio">
                              <div class="flex-center">
                                <v-radio-group
                                  v-model="admission.student_gender"
                                  :rules="[validationRules.required]"
                                  row
                                >
                                  <v-radio
                                    :label="$t('Male')"
                                    value="male"
                                  ></v-radio>
                                  <v-radio
                                    :label="$t('Female')"
                                    value="female"
                                  ></v-radio>
                                </v-radio-group>
                              </div>
                              <div v-if="validation_errors.student_gender">
                                <p
                                  class="error"
                                  v-for="(
                                    error, index
                                  ) in validation_errors.student_gender"
                                  :key="index"
                                >
                                  {{ error }}
                                </p>
                              </div>
                              <!-- v-model.trim="admission.student_gender" -->
                            </div>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col xs="4" md="4">
                            <p>
                              <strong
                                >{{ $t("Applicant Nationality") }}:</strong
                              >
                            </p>
                          </v-col>
                          <v-col xs="8" class="" md="8">
                            <div class="select">
                              <v-autocomplete
                                :items="dataObject.nationalities"
                                item-text="name"
                                item-value="id"
                                v-model.trim="admission.student_nationality_id"
                                :rules="[validationRules.required]"
                                :select="onSelect()"
                                @change="onFocus()"
                                solo
                              >
                              </v-autocomplete>

                              <div
                                v-if="validation_errors.student_nationality_id"
                              >
                                <p
                                  class="error--text"
                                  v-for="(
                                    error, index
                                  ) in validation_errors.student_nationality_id"
                                  :key="index"
                                >
                                  {{ error }}
                                </p>
                              </div>
                            </div>
                          </v-col>
                        </v-row>

                        <v-row v-if="editable && saudi">
                          <v-col xs="4" md="4">
                            <p>
                              <strong> {{ $t("National ID") }}:</strong>
                            </p>
                          </v-col>
                          <v-col xs="8" class="" md="8">
                            <v-text-field
                              @focus="onFocus()"
                              v-model.trim="admission.student_national_id"
                              :rules="[
                                validationRules.required,
                                validationRules.numeric,
                              ]"
                              solo
                              dense
                            ></v-text-field>

                            <div v-if="validation_errors.student_national_id">
                              <p
                                class="error--text"
                                v-for="(
                                  error, index
                                ) in validation_errors.student_national_id"
                                :key="index"
                              >
                                {{ error }}
                              </p>
                            </div>
                          </v-col>
                        </v-row>

                        <v-row v-if="editable && !saudi">
                          <v-col xs="4" md="4">
                            <p>
                              <strong> {{ $t("Passport Number") }}:</strong>
                            </p>
                          </v-col>
                          <v-col xs="8" class="" md="8">
                            <v-text-field
                              @focus="onFocus()"
                              v-model.trim="admission.student_passport_no"
                              :rules="[
                                validationRules.required,
                                validationRules.spaces,
                              ]"
                              solo
                              dense
                            ></v-text-field>

                            <div v-if="validation_errors.student_passport_no">
                              <p
                                class="error--text"
                                v-for="(
                                  error, index
                                ) in validation_errors.student_passport_no"
                                :key="index"
                              >
                                {{ error }}
                              </p>
                            </div>
                          </v-col>
                        </v-row>

                        <v-row v-if="editable && !saudi">
                          <v-col xs="4" md="4">
                            <p>
                              <strong> {{ $t("Iqama No") }}:</strong>
                            </p>
                          </v-col>
                          <v-col xs="8" class="" md="8">
                            <v-text-field
                              @focus="onFocus()"
                              v-model.trim="admission.student_iqama_no"
                              :rules="[
                                validationRules.required,
                                validationRules.alphaNum,
                                validationRules.spaces,
                              ]"
                              solo
                              dense
                            ></v-text-field>

                            <div v-if="validation_errors.student_iqama_no">
                              <p
                                class="error--text"
                                v-for="(
                                  error, index
                                ) in validation_errors.student_iqama_no"
                                :key="index"
                              >
                                {{ error }}
                              </p>
                            </div>
                          </v-col>
                        </v-row>

                        <!-- <v-row v-if="editable && !saudi">
                          <v-col xs="4" md="4">
                            <p>
                              <strong> Iqama No Expiry Date:</strong>
                            </p>
                          </v-col>
                          <v-col xs="8" class="" md="8">
                            <v-text-field
                              id="expiry"
                              @focus="onFocus()"
                              v-model.trim="
                                admission.student_iqama_no_expire_date
                              "
                              :rules="[validationRules.required]"
                              autocomplete="off"
                              solo
                              dense
                              @keydown.prevent
                              @paste.prevent
                              @drop.prevent
                            ></v-text-field>

                            <div
                              v-if="
                                validation_errors.student_iqama_no_expire_date
                              "
                            >
                              <p
                                class="error--text"
                                v-for="(error,
                                index) in validation_errors.student_iqama_no_expire_date"
                                :key="index"
                              >
                                {{ error }}
                              </p>
                            </div>
                          </v-col>
                        </v-row> -->

                        <v-row class="form-item" v-if="editable && !saudi">
                          <v-col xs="4" md="4">
                            <p>
                              <strong>
                                {{ $t("Iqama No Expiry Date") }}:</strong
                              >
                            </p>
                          </v-col>
                          <v-col cols="12" md="8" style="margin-top: -10px">
                            <v-radio-group v-model="student_iqama_no_date_type">
                              <v-row>
                                <v-col cols="6" md="6" sm="6"
                                  ><v-radio
                                    value="hijri"
                                    :label="$t('Hijri')"
                                  ></v-radio
                                ></v-col>
                                <v-col cols="6" md="6" sm="6"
                                  ><v-radio
                                    value="gregorian"
                                    :label="$t('Gregorian')"
                                  ></v-radio
                                ></v-col>
                              </v-row>
                            </v-radio-group>
                          </v-col>
                          <v-col
                            cols="12"
                            md="4"
                            style="margin-top: -20px"
                          ></v-col>
                          <v-col cols="12" md="8" style="margin-top: -20px">
                            <v-text-field
                              id="iqama_geo"
                              @focus="onFocus()"
                              v-show="student_iqama_no_date_type == 'gregorian'"
                              autocomplete="off"
                              v-model.trim="
                                admission.student_iqama_no_expire_date
                              "
                              :rules="[validationRules.required]"
                              solo
                              dense
                              @keydown.prevent
                              @paste.prevent
                              @drop.prevent
                            ></v-text-field>

                            <v-text-field
                              id="hijri2"
                              v-show="student_iqama_no_date_type == 'hijri'"
                              @focus="onFocus()"
                              autocomplete="off"
                              v-model.lazy="
                                admission.student_iqama_no_expire_date_hijri
                              "
                              :rules="[validationRules.required]"
                              solo
                              dense
                              @keydown.prevent
                              @paste.prevent
                              @drop.prevent
                            ></v-text-field>

                            <div
                              v-if="
                                validation_errors.student_iqama_no_expire_date
                              "
                            >
                              <p
                                class="error--text"
                                v-for="(
                                  error, index
                                ) in validation_errors.student_iqama_no_expire_date"
                                :key="index"
                              >
                                {{ error }}
                              </p>
                            </div>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col xs="4" md="4">
                            <p>
                              <strong>{{ $t("Applicant Religion") }}:</strong>
                            </p>
                          </v-col>
                          <v-col xs="8" class="" md="8">
                            <div class="select">
                              <v-select
                                :items="religions"
                                item-text="key"
                                item-value="value"
                                @focus="onFocus()"
                                v-model.trim="admission.student_religion"
                                :rules="[validationRules.required]"
                                dense
                                solo
                              ></v-select>
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="lighten-5" style="" justify="center">
        <v-col cols="12">
          <v-row>
            <v-col class="second-info" cols="12" sm="6">
              <v-card class="mx-auto" shaped>
                <v-card-text>
                  <v-col cols="12">
                    <h3 id="info-header2" class="info-header">
                      {{ $t("Applicant Details") }}
                    </h3>
                  </v-col>
                  <div class="info-div">
                    <!-- ====================================== show data view==================================== -->
                    <div class="viewData">
                      <v-row v-if="!editable">
                        <v-col xs="4" md="4">
                          <p>
                            <strong> {{ $t("Curriculum") }}:</strong>
                          </p>
                        </v-col>
                        <v-col xs="8" class="" md="8">
                          <p class="value">
                            {{ admission_show.student_curriculum }}
                          </p>
                        </v-col>
                      </v-row>

                      <v-row v-if="!editable">
                        <v-col xs="4" md="4">
                          <p>
                            <strong> {{ $t("Previous School") }}:</strong>
                          </p>
                        </v-col>
                        <v-col xs="8" class="" md="8">
                          <p class="value">
                            {{ admission_show.student_previous_school }}
                          </p>
                        </v-col>
                      </v-row>

                      <v-row v-if="!editable">
                        <v-col xs="4" md="4">
                          <p>
                            <strong>{{ $t("Native Language") }}:</strong>
                          </p>
                        </v-col>
                        <v-col xs="8" class="" md="8">
                          <p class="value">
                            {{ admission_show.student_native_language }}
                          </p>
                        </v-col>
                      </v-row>

                      <v-row v-if="!editable">
                        <v-col xs="4" md="4">
                          <p>
                            <strong>{{ $t("Second Language") }}:</strong>
                          </p>
                        </v-col>
                        <v-col
                          xs="8"
                          class=""
                          md="8"
                          v-if="admission_show.student_second_language"
                        >
                          <p class="value">
                            {{ admission_show.student_second_language }}
                          </p>
                        </v-col>
                        <v-col
                          xs="8"
                          class=""
                          md="8"
                          v-if="!admission_show.student_second_language"
                        >
                          <p class="value">
                            {{ $t("No Data Selected") }}
                          </p>
                        </v-col>
                      </v-row>

                      <v-row v-if="!editable">
                        <v-col xs="4" md="4">
                          <p>
                            <strong> {{ $t("Grade Level") }}:</strong>
                          </p>
                        </v-col>
                        <v-col xs="8" class="" md="8">
                          <p class="value">
                            {{ admission_show.student_grade_level }}
                          </p>
                        </v-col>
                      </v-row>
                    </div>

                    <!-- ========================================== edit data view ======================================= -->
                    <div class="editData" v-if="editable">
                      <v-row v-if="editable">
                        <v-col xs="4" md="4">
                          <p>
                            <strong> {{ $t("Curriculum") }}:</strong>
                          </p>
                        </v-col>
                        <v-col xs="8" class="" md="8">
                          <v-text-field
                            @focus="onFocus()"
                            v-model.trim="admission.student_curriculum"
                            :rules="[validationRules.maxLength50]"
                            solo
                            dense
                          ></v-text-field>

                          <div v-if="validation_errors.student_curriculum">
                            <p
                              class="error--text"
                              v-for="(
                                error, index
                              ) in validation_errors.student_curriculum"
                              :key="index"
                            >
                              {{ error }}
                            </p>
                          </div>
                        </v-col>
                      </v-row>

                      <v-row v-if="editable">
                        <v-col xs="4" md="4">
                          <p>
                            <strong> {{ $t("Previous School") }}:</strong>
                          </p>
                        </v-col>
                        <v-col xs="8" class="" md="8">
                          <v-text-field
                            @focus="onFocus()"
                            v-model.trim="admission.student_previous_school"
                            :rules="[validationRules.maxLength50]"
                            solo
                            dense
                          ></v-text-field>

                          <div v-if="validation_errors.student_previous_school">
                            <p
                              class="error--text"
                              v-for="(
                                error, index
                              ) in validation_errors.student_curriculum"
                              :key="index"
                            >
                              {{ error }}
                            </p>
                          </div>
                        </v-col>
                      </v-row>

                      <v-row v-if="editable">
                        <v-col xs="4" md="4">
                          <p>
                            <strong>{{ $t("Native Language") }}:</strong>
                          </p>
                        </v-col>
                        <v-col xs="8" class="" md="8">
                          <div class="select">
                            <v-select
                              :items="languages"
                              item-text="key"
                              item-value="value"
                              id="native-language"
                              v-model="admission.student_native_language"
                              :rules="[validationRules.required]"
                              dense
                              solo
                            ></v-select>

                            <div
                              v-if="validation_errors.student_native_language"
                            >
                              <p
                                class="error--text"
                                v-for="(
                                  error, index
                                ) in validation_errors.student_native_language"
                                :key="index"
                              >
                                {{ error }}
                              </p>
                            </div>
                          </div>
                        </v-col>
                      </v-row>

                      <v-row v-if="editable">
                        <v-col xs="4" md="4">
                          <p>
                            <strong>{{ $t("Second Language") }}:</strong>
                          </p>
                        </v-col>
                        <v-col xs="8" class="" md="8">
                          <v-select
                            :items="languages"
                            item-text="key"
                            item-value="value"
                            @change="onFocus()"
                            id="second-language"
                            v-model.trim="admission.student_second_language"
                            dense
                            solo
                          ></v-select>
                        </v-col>
                      </v-row>

                      <v-row v-if="editable">
                        <v-col xs="4" md="4">
                          <p>
                            <strong> {{ $t("Grade Level") }}:</strong>
                          </p>
                        </v-col>
                        <v-col xs="8" class="" md="8">
                          <v-select
                            :items="dataObject.grades"
                            item-text="grade_name"
                            item-value="id"
                            id="grade-level"
                            v-model.trim="
                              $v.admission.student_grade_level.$model
                            "
                            dense
                            solo
                          ></v-select>
                          <p
                            class="error--text"
                            v-if="gradeError"
                            :class="gradeError ? '' : 'hidden'"
                          >
                            {{ errorOpenGradeMessage }}
                          </p>

                          <div v-if="validation_errors.student_grade_level">
                            <p
                              class="error--text"
                              v-for="(
                                error, index
                              ) in validation_errors.student_grade_level"
                              :key="index"
                            >
                              {{ error }}
                            </p>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col class="second-info" cols="12" sm="6">
              <v-card class="mx-auto" shaped>
                <v-card-text>
                  <v-col cols="12">
                    <h3 id="info-header2" class="info-header">
                      {{ $t("Contact Person Details") }}
                    </h3>
                  </v-col>
                  <div class="info-div contact-info">
                    <!-- ====================================== show data view==================================== -->
                    <div class="viewData" v-if="!editable">
                      <v-row>
                        <v-col xs="4" md="4">
                          <p>
                            <strong>{{ $t("Name") }}:</strong>
                          </p>
                        </v-col>
                        <v-col xs="8" class="" md="8">
                          <p class="value">
                            {{ admission_show.contact_person_name }}
                          </p>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col xs="4" md="4">
                          <p>
                            <strong>{{ $t("Mobile Number") }}:</strong>
                          </p>
                        </v-col>
                        <v-col xs="8" class="" md="8">
                          <p class="value">
                            {{ admission_show.contact_person_country_code
                            }}{{ admission_show.contact_person_mobile }}
                          </p></v-col
                        >
                      </v-row>

                      <v-row>
                        <v-col xs="4" md="4">
                          <p>
                            <strong>{{ $t("Email") }}:</strong>
                          </p>
                        </v-col>
                        <v-col xs="8" class="" md="8"
                          ><p class="value">
                            {{ admission_show.contact_person_email }}
                          </p>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col xs="4" md="4">
                          <p>
                            <strong> {{ $t("Nationality") }}:</strong>
                          </p>
                        </v-col>
                        <v-col xs="8" md="8" class="">
                          <p class="value">
                            {{ admission_show.contact_nationality_id }}
                          </p>
                        </v-col>
                      </v-row>
                      <v-row v-if="!editable && saudi">
                        <v-col xs="4" md="4">
                          <p>
                            <strong> {{ $t("National ID") }}:</strong>
                          </p>
                        </v-col>
                        <v-col xs="8" class="" md="8">
                          <p class="value">
                            {{ admission_show.contact_national_id }}
                          </p>
                        </v-col>
                      </v-row>

                      <v-row v-if="!editable" :class="{ hidden: saudi }">
                        <v-col xs="4" md="4">
                          <p>
                            <strong> {{ $t("Iqama No") }}:</strong>
                          </p>
                        </v-col>
                        <v-col xs="8" class="" md="8">
                          <p class="value">
                            {{ admission_show.contact_iqama_no }}
                          </p>
                        </v-col>
                      </v-row>

                      <v-row v-if="!editable" :class="{ hidden: saudi }">
                        <v-col xs="4" md="4">
                          <p>
                            <strong> {{ $t("Iqama No Expiry Date") }}:</strong>
                          </p>
                        </v-col>
                        <v-col xs="8" class="" md="8">
                          <p class="value">
                            {{ admission_show.contact_iqama_no_expire_date }}
                            <v-icon
                              v-if="
                                admission_show.iqama_class_contact == 'green'
                              "
                              :title="admission_show.iqama_class_title_contact"
                              class="green--text"
                              >fiber_manual_record</v-icon
                            >
                            <v-icon
                              v-if="admission_show.iqama_class_contact == 'red'"
                              :title="admission_show.iqama_class_title_contact"
                              class="red--text"
                              >fiber_manual_record</v-icon
                            >

                            <v-icon
                              v-if="
                                admission_show.iqama_class_contact == 'yellow'
                              "
                              :title="admission_show.iqama_class_title_contact"
                              class="yellow--text"
                              >fiber_manual_record</v-icon
                            >
                          </p>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col xs="4" md="4">
                          <p>
                            <strong> {{ $t("Relationship") }}:</strong>
                          </p>
                        </v-col>
                        <v-col xs="8" class="" md="8"
                          ><p
                            class="value hidden"
                            :class="{ showInput: !other }"
                          >
                            {{
                              admission_show.contact_person_relationship
                                .charAt(0)
                                .toUpperCase() +
                              admission_show.contact_person_relationship.slice(
                                1
                              )
                            }}
                          </p>
                          <p class="value hidden" :class="{ showInput: other }">
                            {{
                              admission_show.contact_person_relationship_other
                            }}
                          </p>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col xs="4" md="4">
                          <p>
                            <strong> {{ $t("Communication Method") }}: </strong>
                          </p>
                        </v-col>
                        <v-col xs="8" class="" md="8">
                          <div class="form-group">
                            <v-row>
                              <v-checkbox
                                class="mx-2"
                                :label="$t('SMS')"
                                id="sms"
                                value="sms"
                                disabled
                                v-model="
                                  $v.admission
                                    .contact_person_communication_method.$model
                                "
                              ></v-checkbox>

                              <v-checkbox
                                class="mx-2"
                                :label="$t('E-mail')"
                                id="email"
                                value="email"
                                disabled
                                v-model="
                                  $v.admission
                                    .contact_person_communication_method.$model
                                "
                              ></v-checkbox>
                            </v-row>
                          </div>
                        </v-col>
                      </v-row>
                    </div>

                    <!-- ========================================== edit data view ======================================= -->
                    <div class="editData" v-if="editable">
                      <v-row>
                        <v-col xs="4" md="4">
                          <p>
                            <strong>{{ $t("Name") }}:</strong>
                          </p>
                        </v-col>
                        <v-col xs="8" class="" md="8">
                          <v-text-field
                            @focus="onFocus()"
                            v-model.trim="admission.contact_person_name"
                            :rules="[
                              validationRules.required,
                              validationRules.maxLength50,
                            ]"
                            solo
                            dense
                          ></v-text-field>

                          <div v-if="validation_errors.contact_person_name">
                            <p
                              class="error--text"
                              v-for="(
                                error, index
                              ) in validation_errors.contact_person_name"
                              :key="index"
                            >
                              {{ error }}
                            </p>
                          </div>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col xs="4" md="4">
                          <p>
                            <strong>{{ $t("Mobile Number") }}:</strong>
                          </p>
                        </v-col>
                        <v-col xs="3" sm="3" class="no-paddingL">
                          <div
                            class="code applicant-country-code"
                            id="country-code"
                          >
                            <v-autocomplete
                              :items="dataObject.Codes"
                              class="form-control"
                              @change="hideLabel = true"
                              outlined
                              item-text="title"
                              item-value="value"
                              value="187"
                              v-model="admission.country_code"
                              ><template slot="selection" slot-scope="data">
                                <v-flex xs2>
                                  <v-avatar size="25px">
                                    <img :src="data.item.icon" />
                                  </v-avatar>
                                </v-flex>
                                <v-flex class="ml-4">
                                  {{ data.item.title }}
                                </v-flex>
                              </template>
                              <template slot="item" slot-scope="data">
                                <v-list-item-avatar
                                  style="
                                    width: 20px;
                                    min-width: 20px;
                                    height: 20px;
                                  "
                                >
                                  <img :src="data.item.icon" />
                                </v-list-item-avatar>
                                <v-list-item-content>
                                  <v-list-item-title
                                    v-html="data.item.title"
                                  ></v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </v-autocomplete>
                          </div>
                        </v-col>
                        <v-col xs="5" class="" md="5">
                          <v-text-field
                            @focus="onFocus()"
                            v-model.trim="admission.contact_person_mobile"
                            :rules="[
                              validationRules.required,
                              validationRules.phoneNum,
                              mobileRule(
                                admission.country_code,
                                admission.contact_person_mobile
                              ),
                            ]"
                            solo
                            dense
                          ></v-text-field>

                          <div v-if="validation_errors.contact_person_mobile">
                            <p
                              class="error--text"
                              v-for="(
                                error, index
                              ) in validation_errors.contact_person_mobile"
                              :key="index"
                            >
                              {{ error }}
                            </p>
                          </div>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col xs="4" md="4">
                          <p>
                            <strong>{{ $t("Email") }}:</strong>
                          </p>
                        </v-col>
                        <v-col xs="8" class="" md="8">
                          <v-text-field
                            @focus="onFocus()"
                            v-model.trim="admission.contact_person_email"
                            :rules="[
                              validationRules.required,
                              validationRules.maxLength50,
                              validationRules.email,
                            ]"
                            solo
                            dense
                          ></v-text-field>

                          <div v-if="validation_errors.contact_person_email">
                            <p
                              class="error--text"
                              v-for="(
                                error, index
                              ) in validation_errors.contact_person_email"
                              :key="index"
                            >
                              {{ error }}
                            </p>
                          </div>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col xs="4" md="4">
                          <p>
                            <strong>{{ $t("Relationship") }}:</strong>
                          </p>
                        </v-col>
                        <v-col xs="8" class="" md="8">
                          <div class="flex-center">
                            <v-radio-group
                              v-model="
                                $v.admission.contact_person_relationship.$model
                              "
                              :rules="[validationRules.required]"
                              row
                              @change="onOther()"
                            >
                              <v-radio
                                :label="$t('Father')"
                                value="father"
                                id="father"
                              ></v-radio>
                              <v-radio
                                :label="$t('Mother')"
                                value="mother"
                                id="mother"
                              ></v-radio>
                              <v-radio
                                :label="$t('Uncle')"
                                value="uncle"
                                id="uncle"
                              ></v-radio>
                              <v-radio
                                :label="$t('Other')"
                                value="other"
                                id="other"
                              ></v-radio>
                            </v-radio-group>

                            <div
                              v-if="
                                validation_errors.contact_person_relationship
                              "
                            >
                              <p
                                class="error"
                                v-for="(
                                  error, index
                                ) in validation_errors.contact_person_relationship"
                                :key="index"
                              >
                                {{ error }}
                              </p>
                            </div>
                          </div>
                        </v-col>
                      </v-row>

                      <v-row v-if="editable && other">
                        <v-col xs="4" md="4">
                          <p><strong></strong></p>
                        </v-col>
                        <v-col xs="8" class="" md="8">
                          <v-text-field
                            @focus="onFocus()"
                            v-model.trim="
                              admission.contact_person_relationship_other
                            "
                            :rules="[validationRules.required]"
                            solo
                            dense
                          ></v-text-field>

                          <div
                            v-if="
                              validation_errors.contact_person_relationship_other
                            "
                          >
                            <p
                              class="error--text"
                              v-for="(
                                error, index
                              ) in validation_errors.contact_person_relationship_other"
                              :key="index"
                            >
                              {{ error }}
                            </p>
                          </div>
                        </v-col>
                      </v-row>

                      <v-row v-if="editable">
                        <v-col xs="4" md="4">
                          <p>
                            <strong
                              >{{
                                $t("Preferred method of communication")
                              }}:</strong
                            >
                          </p>
                        </v-col>
                        <v-col xs="8" class="" md="8">
                          <v-row>
                            <v-checkbox
                              class="mx-2"
                              :label="$t('SMS')"
                              id="sms"
                              value="sms"
                              v-model="
                                admission.contact_person_communication_method
                              "
                            ></v-checkbox>

                            <v-checkbox
                              class="mx-2"
                              :label="$t('E-mail')"
                              id="email"
                              value="email"
                              v-model="
                                admission.contact_person_communication_method
                              "
                            ></v-checkbox>
                          </v-row>

                          <div
                            v-if="
                              validation_errors.contact_person_communication_method
                            "
                          >
                            <p
                              class="error--text"
                              v-for="(
                                error, index
                              ) in validation_errors.contact_person_communication_method"
                              :key="index"
                            >
                              {{ error }}
                            </p>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="editable">
        <v-col
          cols="12"
          justify="center"
          align="center"
          class="submit-div text-center"
        >
          <v-btn
            type="submit"
            class="ui-btn submit"
            @click.prevent="submit()"
            :loading="loading"
            :disabled="loading"
            large
          >
            {{ $t("Save") }}
          </v-btn>
          <v-btn
            class="ui-btn modal-btn-cancel"
            @click.prevent="cancel()"
            large
          >
            {{ $t("Cancel") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-row v-can="'show-applicants-logs'">
      <v-col cols="12" sm="12" class="first-info">
        <v-card class="mx-auto" shaped>
          <v-card-text>
            <v-row class="lighten-5" style="" justify="center">
              <v-col cols="12" style="padding-top: 0; padding-bottom: 0">
                <v-row>
                  <v-col cols="12">
                    <h3 id="info-header" class="info-header">
                      {{ $t("Activity") }}
                    </h3>
                  </v-col>
                  <v-col cols="12" sm="12" id="info-div" class="info-div">
                    <div class="notice-board-wrap">
                      <!-- start div -->
                      <div
                        class="notice-list"
                        v-for="(logItems, date) in logs"
                        :key="date"
                      >
                        <div
                          class="post-date bg-dodger-blue"
                          data-toggle="collapse"
                          :data-target="'#collapse' + date"
                          aria-expanded="false"
                          :aria-controls="'#collapse' + date"
                        >
                          {{ date }}
                        </div>
                        <div
                          :id="'collapse' + date"
                          class="collapse"
                          v-for="(logItem, index) in logItems"
                          :key="index"
                        >
                          <!-- <h6 class="notice-title post-date bg-pink">
                {{ logItem.action }}
              </h6> -->
                          <ul style="padding-left: 0">
                            <li
                              v-for="change in logItem.changes"
                              :key="change"
                              v-html="'- ' + change"
                            ></li>
                          </ul>
                          <div class="entry-meta" style="padding-left: 9px">
                            <span class="user"
                              >{{
                                logItem.action.charAt(0).toUpperCase() +
                                logItem.action.slice(1)
                              }}
                              by
                              {{
                                logItem.action == "updated"
                                  ? logItem.user
                                  : "Applicant"
                              }}</span
                            >
                            at
                            {{ logItem.time }}
                          </div>
                          <hr />
                        </div>
                      </div>
                      <!-- end div -->
                      <router-link
                        v-if="count > 5"
                        target="_blank"
                        :to="'/applicants/log/' + applicant_id"
                        @click.prevent="showmore"
                        style="float: right; margin-top: 20px"
                        class="show-more"
                      >
                        <i class="fas fa-user-clock"></i> {{ $t("Show More") }}
                      </router-link>
                      <div style="clear: both"></div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- <template v-if="admissionLoaded">
      <validateApplicant
        v-bind:data="admission"
        v-on:childToParent="onChildClick"
      ></validateApplicant>
    </template> -->

    <template v-if="admissionLoaded">
      <approveApplicant
        v-bind:data="admission"
        :approveDialog="approveDialog"
        :price_plan_id="price_plan_id"
        v-on:childToParent="onChildClick"
      ></approveApplicant>
    </template>
  </div>
</template>

<script>
import axios from "axios";
import { AdmissionMixin } from "../../mixins/AdmissionMixin";
import { validationMixin } from "../../mixins/validationMixin";
// import validateApplicant from "../../components/validateApplicant.vue";
import approveApplicant from "../../components/modals/approveApplicant.vue";
import ACL from "../../acl";
export default {
  name: "ApplicantsShow",
  mixins: [AdmissionMixin, validationMixin],
  components: {
    approveApplicant,
  },
  data() {
    return {
      loading: false,
      editBtnVisible: ACL.checkPermission("edit-applicants"),
      deleteBtnVisible: ACL.checkPermission("delete-applicants"),
      printBtnVisible: ACL.checkPermission("print-applicants"),
      archiveBtnVisible: ACL.checkPermission(
        "change-applicants-status-archive"
      ),
      approveBtnVisible: ACL.checkPermission(
        "change-applicants-status-approve"
      ),
      valid: false,
      price_plan_id: "",
      approveDialog: false,
      admissionLoaded: false,
      snackbar: false,
      text: "",
      snackColor: "",
      snackTime: 4000,

      admission_show: {
        id: "",
        application_number: "",
        student_birthdate_m: "",
        student_birthdate_h: "",
        student_place_of_birth: "",
        student_gender: "",
        student_nationality_id: "",
        student_religion: "",
        student_native_language: "",
        student_second_language: "",
        student_grade_level: "",
        student_national_id: "",
        student_passport_no: "",
        student_iqama_no: "",
        student_iqama_no_expire_date: null,
        student_curriculum: "",
        student_previous_school: "",
        contact_person_name: "",
        contact_person_email: "",
        contact_person_mobile: "",
        contact_person_relationship: "",
        contact_person_relationship_other: "",
        contact_person_communication_method: [],
        contact_person_information_certify: "1",

        en: {
          student_first_name: "",
          student_father_name: "",
          student_family_name: "",
        },
        ar: {
          student_first_name: "",
          student_father_name: "",
          student_family_name: "",
        },
        in_range: "",
        iqama_in_range: "",
      },
      applicant: "",
      applicant_id: "",
      editable: false,
      submitted: false,
      saudi_id: 187,
      options: ["sms", "email", "phone"],

      dateEpiryMsg: "",
      other: false,
      saudi: false,
      saudiContact: false,
      iconTitle: "icontext",
      approveDisabledBtn: false,
      gradeError: false,
      logs: [],
      count: 0,
      religions: [
        { key: this.$i18n.t("Muslim"), value: "muslim" },
        { key: this.$i18n.t("Non-muslim"), value: "non-muslim" },
      ],
      languages: [
        { key: this.$i18n.t("English"), value: 1 },
        { key: this.$i18n.t("Arabic"), value: 2 },
      ],
    };
  },

  methods: {
    // Triggered when `childToParent` event is emitted by the child.
    onChildClick(value, approveDialog) {
      this.admissionLoaded = value;
      this.approveDialog = approveDialog;
    },
    approveDialogOpen() {
      this.admissionLoaded = true;
      this.approveDialog = true;

      console.log(this.price_plan_id);

      // this.admissionLoaded = true;
      // this.approveDialog = true;
    },

    submit() {
      if (this.valid == true) {
        let id = "/" + this.applicant_id;
        this.submitAdmission(id);
      } else {
        console.log("validate");
        this.validate();
      }
    },
    cancel() {
      // reset validation_errors
      this.validation_errors = [];
      this.$v.$reset();
      this.gradeError = false;
      //edit mode
      axios
        .get(
          this.getApiUrl + "/applicant/applicants/edit/" + this.applicant_id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.admission = response.data.data;
          this.editable = false;
          this.onSelect();
          this.onOther();
        });
    },
    async getpricePlan() {
      await axios
        .get(this.getApiUrl + "/getDefaultFeePlan", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          if (response.data.status.error == false) {
            this.price_plan_id = response.data.data;
          }
          // console.log(this.price_plan_id);
          this.admissionLoaded = true;
        });
    },
  },
  async mounted() {
    if (this.$route.query.editable && this.editBtnVisible) {
      this.editable = this.$route.query.editable;
    }
    this.applicant_id = this.$router.currentRoute.params.applicant_id;

    await axios
      .get(this.getApiUrl + "/applicant/applicants/" + this.applicant_id, {
        headers: {
          Authorization: "Bearer " + localStorage.token,
        },
      })
      .then((response) => {
        this.admission_show = response.data.data;
        this.getpricePlan();
        if (response.data.status.error == true) {
          this.$router.push("/applicants");
        }

        if (
          this.admission_show.status &&
          this.admission_show.status == "approved"
        ) {
          this.approveDisabledBtn = true;
        }
      });
    // edit mode
    axios
      .get(this.getApiUrl + "/applicant/applicants/edit/" + this.applicant_id, {
        headers: {
          Authorization: "Bearer " + localStorage.token,
        },
      })
      .then((response) => {
        this.admission = response.data.data;
        this.onOther();
        this.onSelect();
      });

    // axios.get(this.getApiUrl + "/getGrades?alias=active").then(response => {
    //   this.dataObject.grades = response.data.data.grades;
    // });
    axios
      .get(
        this.getApiUrl +
          "/getGrades?applicant_id=" +
          this.$router.currentRoute.params.applicant_id
      )
      .then((response) => {
        this.dataObject.grades = response.data.data.grades;
      });
    axios
      .get(
        this.getApiUrl +
          "/applicant/getApplicantLogs/" +
          this.applicant_id +
          "/recent",
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        }
      )
      .then((response) => {
        this.logs = response.data.data.logs;
        this.count = response.data.data.count;
      });
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_forms.scss";

.v-application p {
  margin-bottom: 0 !important;
}
.logo-div-img {
  margin-top: 15%;
}

.contact-info {
  padding-bottom: 0 !important;
}
.contact-info .form-group {
  margin-bottom: 0;
}

.first-info .v-card,
.second-info .v-card {
  height: 100%;
}
</style>
