import axios from "axios";
import {
  required,
  maxLength,
  numeric,
  alphaNum,
  email,
  sameAs,
} from "vuelidate/lib/validators";

import moment from "moment";
require("moment/locale/es"); // without this line it didn't work
moment().format();
var moment_hijri = require("moment-hijri");
moment.locale("es");

import "../assets/jquery.calendars.package-2.1.1/css/humanity.calendars.picker.css";
import "../assets/jquery.calendars.package-2.1.1/js/jquery.calendars.js";
import "../assets/jquery.calendars.package-2.1.1/js/jquery.calendars.plus.js";
import "../assets/jquery.calendars.package-2.1.1/js/jquery.calendars.islamic.js";

import "../assets/jquery.calendars.package-2.1.1/js/jquery.plugin.js";
import "../assets/jquery.calendars.package-2.1.1/js/jquery.calendars.picker.js";

export const AdmissionMixin = {
  data() {
    return {
      currentAppLocale:
        this.$route.query.lang != undefined && this.$route.query.lang == "ar"
          ? "ar"
          : "en",
      student_iqama_no_date_type: "hijri",
      contact_iqama_no_date_type: "hijri",
      admission: {
        student_birthdate_m: "",
        student_birthdate_h: "",
        student_place_of_birth: "",
        student_gender: "",
        student_nationality_id: 187,
        student_religion: "",
        student_native_language: "",
        student_second_language: "",
        student_grade_level: "",
        student_national_id: "",
        student_passport_no: "",
        student_iqama_no: "",
        student_iqama_no_expire_date: "",
        student_iqama_no_expire_date_hijri: "",
        student_curriculum: "",
        student_previous_school: "",
        contact_person_name: "",
        country_code: "",
        contact_person_email: "",
        contact_person_mobile: "",
        contact_person_relationship: "",
        contact_person_relationship_other: "",
        contact_person_communication_method: [],
        contact_person_information_certify: "",
        // new
        contact_nationality_id: 187,
        contact_national_id: "",
        contact_iqama_no: "",
        contact_iqama_no_expire_date: "",
        contact_iqama_no_expire_date_hijri: "",
        en: {
          student_first_name: "",
          student_father_name: "",
          student_grand_father_name: "",
          student_family_name: "",
        },
        ar: {
          student_first_name: "",
          student_father_name: "",
          student_grand_father_name: "",
          student_family_name: "",
        },
      },

      dataObject: {
        nationalities: [],
        grades: [],
        Codes: [],
      },
      validation_errors: [],
      saudi: true,
      saudiContact: true,
      saudi_id: 187,
      submitted: false,
      errorOpenGradeMessage: "",
    };
  },
  validations: {
    admission: {
      student_birthdate_m: { required },
      student_birthdate_h: { required },
      student_place_of_birth: { required, maxLength: maxLength(50) },
      student_gender: { required },
      student_nationality_id: { required },
      contact_nationality_id: { required },
      student_national_id: { required, numeric },
      contact_national_id: { required, numeric },
      student_passport_no: { required, alphaNum },
      student_iqama_no: { required, alphaNum },
      student_iqama_no_expire_date: { required },
      contact_iqama_no: { required, alphaNum },
      contact_iqama_no_expire_date: { required },
      student_curriculum: { maxLength: maxLength(50) },
      student_previous_school: { maxLength: maxLength(50) },
      student_religion: { required },
      student_native_language: { required },
      student_second_language: { required },
      student_grade_level: { required },
      contact_person_name: { required, maxLength: maxLength(50) },
      contact_person_email: { required, email, maxLength: maxLength(50) },
      contact_person_mobile: {
        required,
        maxLength: maxLength(20),
        phoneNum(value) {
          return /^(\+?)[0-9]+$/.test(value); // checks for +
          // checks for 0-9
        },
      },
      contact_person_relationship: { required },
      contact_person_relationship_other: { required },
      contact_person_communication_method: { required },
      contact_person_information_certify: { sameAs: sameAs(() => true) },
      en: {
        student_first_name: { required, maxLength: maxLength(25) },
        student_father_name: { maxLength: maxLength(25) },
        student_grand_father_name: { maxLength: maxLength(25) },
        student_family_name: { required, maxLength: maxLength(25) },
      },
      ar: {
        student_first_name: { required, maxLength: maxLength(25) },
        student_father_name: { maxLength: maxLength(25) },
        student_grand_father_name: { maxLength: maxLength(25) },
        student_family_name: { required, maxLength: maxLength(25) },
      },
    },
  },
  methods: {
    checktab(tab) {
      if (tab == 1 && this.valid1 == true) {
        return false;
      }
      if (tab == 2 && this.valid1 == true) {
        return false;
      }
      if (
        tab == 3 &&
        this.valid1 == true &&
        this.valid2 == true &&
        this.gradeError != true
      ) {
        return false;
      }
      return true;
    },

    checkGrade() {
      if (this.admission.student_grade_level != "") {
        //checkGradeOpen
        axios
          .post(this.getApiUrl + "/checkGradeOpen", {
            alias: this.$router.currentRoute.params.alias,
            grade_id: this.admission.student_grade_level,
            gender: this.admission.student_gender,
          })
          .then((response) => {
            this.gradeError = !response.data.data.open;
            if (this.gradeError) {
              this.errorOpenGradeMessage = response.data.data.message;
            }
          });
      } else {
        this.gradeError = false;
      }
    },
    submitAdmission(applicantID = "") {
      if (applicantID == "") {
        this.checkGrade();
      }

      if (this.gradeError == false) {
        this.submitted = true;
        if (applicantID == "") {
          this.disabledSubmitbtn = true;
        }
        this.loading = true;
        axios
          .post(
            this.getApiUrl + "/applicant/registerApplicantData" + applicantID,
            this.admission,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
                //the token is a variable which holds the token
              },
            }
          )
          .then((response) => {
            this.loading = false;
            this.resetAdmissionValidation();
            this.submitted = false;

            if (response.data.status.error == true) {
              this.disabledSubmitbtn = false;
              this.validation_errors = response.data.status.validation_errors;
              this.snack = true;
              this.snackColor = "red";
              if (this.currentAppLocale == "ar") {
                this.snackText = "من فضلك تحقق من الاخطاء";
              } else {
                this.snackText = "Check Validation Errors";
              }

              if (applicantID == "") {
                this.success = false;
              }

              if (applicantID != "") {
                this.success = false;
                this.snackbar = true;
                this.snackColor = "error";
                if (this.currentAppLocale == "ar") {
                  this.text = "خطا في تحديث البيانات";
                } else {
                  this.text = "Error in Updating Data";
                }
              }
            } else {
              if (applicantID == "") {
                this.success = true;
                this.disabledSubmitbtn = false;
                this.successMsg = response.data.status.message;
                this.requestNum = response.data.data.application_number;
                this.applicant_name =
                  response.data.data.student_first_name +
                  " " +
                  response.data.data.student_father_name +
                  " " +
                  response.data.data.student_family_name;
              } else {
                this.success = true;
                this.snackbar = true;
                this.snackColor = "success";
                if (this.currentAppLocale == "ar") {
                  this.text = "تم تحديث البيانات";
                } else {
                  this.text = "Data Updated";
                }

                this.editable = false;
                this.admission_show = response.data.data;
              }
            }
          });
      }
    },
    onOther() {
      if (this.admission.contact_person_relationship == "other") {
        this.other = true;
      } else {
        this.other = false;
        this.admission.contact_person_relationship_other = "";
      }
    },
    onSelect() {
      if (this.admission.student_nationality_id == 187) {
        this.saudi = true;
        this.admission.student_passport_no = "";
        this.admission.student_iqama_no = "";
        this.admission.student_iqama_no_expire_date = null;
        this.admission.student_iqama_no_expire_date_hijri = null;
      } else {
        var _this = this;
        $(document).ready(function () {
          $("#hijri2").calendarsPicker({
            dateFormat: "dd/mm/yyyy",
            calendar: $.calendars.instance("islamic"),
            onSelect: function (date) {
              if (date[0]) {
                var dateH =
                  date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
                _this.admission.student_iqama_no_expire_date_hijri = dateH;
              } else {
                _this.admission.student_iqama_no_expire_date_hijri = "";
              }
            },
          });
        });
        this.saudi = false;
        this.admission.student_national_id = "";
        this.activateExpiryDatePicker();
      }
    },

    activateExpiryDatePicker() {
      let _this = this;
      $(document).ready(function () {
        $("#expiry").calendarsPicker({
          dateFormat: "dd/mm/yyyy",
          onSelect: function (date) {
            if (date[0]) {
              var dateM =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;

              dateM = moment_hijri(dateM, "DD/MM/YYYY").format("DD/MM/YYYY");
              _this.admission.student_iqama_no_expire_date = dateM;
            } else {
              _this.admission.student_iqama_no_expire_date = "";
            }
          },
        });
      });
    },

    // ****************************************

    onSelectContact() {
      if (this.admission.contact_nationality_id == 187) {
        this.saudiContact = true;
        this.admission.contact_iqama_no = "";
        this.admission.contact_iqama_no_expire_date = null;
        this.admission.contact_iqama_no_expire_date_hijri = null;
      } else {
        var _this = this;
        $(document).ready(function () {
          $("#hijri2-contact").calendarsPicker({
            dateFormat: "dd/mm/yyyy",
            calendar: $.calendars.instance("islamic"),
            onSelect: function (date) {
              if (date[0]) {
                var dateH =
                  date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
                _this.admission.contact_iqama_no_expire_date_hijri = dateH;
              } else {
                _this.admission.contact_iqama_no_expire_date_hijri = "";
              }
            },
          });
        });
        this.saudiContact = false;
        this.admission.contact_national_id = "";
        this.activateExpiryDatePickerContact();
      }
    },

    activateExpiryDatePickerContact() {
      let _this = this;
      $(document).ready(function () {
        $("#expiry").calendarsPicker({
          dateFormat: "dd/mm/yyyy",
          onSelect: function (date) {
            if (date[0]) {
              var dateM =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;

              dateM = moment_hijri(dateM, "DD/MM/YYYY").format("DD/MM/YYYY");
              _this.admission.contact_iqama_no_expire_date = dateM;
            } else {
              _this.admission.contact_iqama_no_expire_date = "";
            }
          },
        });
      });
    },
    // ****************************************

    onFocus() {
      this.error = false;
      this.submitted = false;
      this.validation_errors = [];
    },
    editMode() {
      var _this = this;
      this.editable = true;
      this.$v.$reset();
      this.validation_errors = [];
      $(function () {
        $("#hijri").calendarsPicker({
          maxDate: 0,
          calendar: $.calendars.instance("islamic"),
          dateFormat: "dd/mm/yyyy",
          onSelect: function (date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.admission.student_birthdate_h = dateH;
            } else {
              _this.admission.student_birthdate_h = "";
            }
          },
        });
        $("#gregorian").calendarsPicker({
          dateFormat: "dd/mm/yyyy",
          maxDate: 0,
          onSelect: function (date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.admission.student_birthdate_m = dateH;
            } else {
              _this.admission.student_birthdate_m = "";
            }
          },
        });

        $("#expiry").calendarsPicker({
          dateFormat: "dd/mm/yyyy",
          onSelect: function (date) {
            if (date[0]) {
              var dateM =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;

              dateM = moment_hijri(dateM, "DD/MM/YYYY").format("DD/MM/YYYY");
              _this.admission.student_iqama_no_expire_date = dateM;
            } else {
              _this.admission.student_iqama_no_expire_date = "";
            }
          },
        });
      });
    },
    resetAdmissionValidation() {
      if (this.$refs.form1 && this.$refs.form2 && this.$refs.form3) {
        this.$refs.form1.resetValidation();
        this.$refs.form2.resetValidation();
        this.$refs.form3.resetValidation();
      }
    },
  },
  watch: {
    student_iqama_no_date_type: {
      handler() {
        if (this.student_iqama_no_date_type != "hijri") {
          console.log("geooo");
          let _this = this;
          $(function () {
            $("#iqama_geo").calendarsPicker({
              dateFormat: "dd/mm/yyyy",
              onSelect: function (date) {
                if (date[0]) {
                  var dateH =
                    date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
                  _this.admission.student_iqama_no_expire_date = dateH;
                } else {
                  _this.admission.student_iqama_no_expire_date = "";
                }
              },
            });
          });
        } else {
          let _this = this;
          $(function () {
            $("#hijri2").calendarsPicker({
              dateFormat: "dd/mm/yyyy",
              calendar: $.calendars.instance("islamic"),
              onSelect: function (date) {
                if (date[0]) {
                  var dateH =
                    date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
                  _this.admission.student_iqama_no_expire_date_hijri = dateH;
                } else {
                  _this.admission.student_iqama_no_expire_date_hijri = "";
                }
              },
            });
          });
        }
        // let pageNumber = this.$router.currentRoute.params.pageNumber;
        // this.getDataFromApi(pageNumber);
      },
    },
    "admission.student_birthdate_m": function (v) {
      if (v != "") {
        var m = moment_hijri(v, "DD/MM/YYYY");
        this.admission.student_birthdate_h = m.format("iDD/iMM/iYYYY");
      } else {
        this.admission.student_birthdate_h = "";
      }
    },
    "admission.student_birthdate_h": function (v) {
      if (v != "") {
        var h = moment_hijri(v, "iDD/iMM/iYYYY");
        this.admission.student_birthdate_m = h.format("DD/MM/YYYY");
      } else {
        this.admission.student_birthdate_m = "";
      }
    },
    "admission.student_iqama_no_expire_date": function (v) {
      if (v != "" && v != null) {
        var m = moment_hijri(v, "DD/MM/YYYY");
        this.admission.student_iqama_no_expire_date_hijri =
          m.format("iDD/iMM/iYYYY");
      } else {
        this.admission.student_iqama_no_expire_date_hijri = "";
      }
    },
    "admission.student_iqama_no_expire_date_hijri": function (v) {
      if (v != "" && v != null) {
        var h = moment_hijri(v, "iDD/iMM/iYYYY");
        this.admission.student_iqama_no_expire_date = h.format("DD/MM/YYYY");
      } else {
        this.admission.student_iqama_no_expire_date = "";
      }
    },
    // ************************************
    contact_iqama_no_date_type: {
      handler() {
        if (this.contact_iqama_no_date_type != "hijri") {
          console.log("geooo");
          let _this = this;
          $(function () {
            $("#iqama_geo-contact").calendarsPicker({
              dateFormat: "dd/mm/yyyy",
              onSelect: function (date) {
                if (date[0]) {
                  var dateH =
                    date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
                  _this.admission.contact_iqama_no_expire_date = dateH;
                } else {
                  _this.admission.contact_iqama_no_expire_date = "";
                }
              },
            });
          });
        } else {
          let _this = this;
          $(function () {
            $("#hijri2-contact").calendarsPicker({
              dateFormat: "dd/mm/yyyy",
              calendar: $.calendars.instance("islamic"),
              onSelect: function (date) {
                if (date[0]) {
                  var dateH =
                    date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
                  _this.admission.contact_iqama_no_expire_date_hijri = dateH;
                } else {
                  _this.admission.contact_iqama_no_expire_date_hijri = "";
                }
              },
            });
          });
        }
        // let pageNumber = this.$router.currentRoute.params.pageNumber;
        // this.getDataFromApi(pageNumber);
      },
    },
    "admission.contact_iqama_no_expire_date": function (v) {
      if (v != "" && v != null) {
        var m = moment_hijri(v, "DD/MM/YYYY");
        this.admission.contact_iqama_no_expire_date_hijri =
          m.format("iDD/iMM/iYYYY");
      } else {
        this.admission.contact_iqama_no_expire_date_hijri = "";
      }
    },
    "admission.contact_iqama_no_expire_date_hijri": function (v) {
      if (v != "" && v != null) {
        var h = moment_hijri(v, "iDD/iMM/iYYYY");
        this.admission.contact_iqama_no_expire_date = h.format("DD/MM/YYYY");
      } else {
        this.admission.contact_iqama_no_expire_date = "";
      }
    },
    // ************************************
  },
  mounted() {
    var _this = this;

    $(function () {
      $("#hijri").calendarsPicker({
        maxDate: 0,
        dateFormat: "dd/mm/yyyy",
        calendar: $.calendars.instance("islamic"),
        onSelect: function (date) {
          if (date[0]) {
            var dateH =
              date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
            _this.admission.student_birthdate_h = dateH;
          } else {
            _this.admission.student_birthdate_h = "";
          }
        },
      });
      $("#gregorian").calendarsPicker({
        maxDate: 0,
        dateFormat: "dd/mm/yyyy",
        onSelect: function (date) {
          if (date[0]) {
            var dateH =
              date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
            _this.admission.student_birthdate_m = dateH;
          } else {
            _this.admission.student_birthdate_m = "";
          }
        },
      });

      $("#expiry").calendarsPicker({
        dateFormat: "dd/mm/yyyy",
        onSelect: function (date) {
          if (date[0]) {
            var dateM =
              date[0]._day + "/" + date[0]._month + "/" + date[0]._year;

            dateM = moment_hijri(dateM, "DD/MM/YYYY").format("DD/MM/YYYY");
            _this.admission.student_iqama_no_expire_date = dateM;
          } else {
            _this.admission.student_iqama_no_expire_date = "";
          }
        },
      });
    });

    if (this.$router.currentRoute.params.alias) {
      axios
        .post(
          this.getApiUrl + "/applicant/checkAdmission",
          { alias: this.$router.currentRoute.params.alias },
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          // console.log(response.data.data);
          if (response.data.data.check == false) {
            // redirect to not found
            window.location = "/404";
          }
        });
      axios
        .get(
          this.getApiUrl +
            "/getGrades?alias=" +
            this.$router.currentRoute.params.alias,
          {
            headers: {
              locale: this.currentAppLocale,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          this.dataObject.grades = response.data.data.grades;
        });
    } else {
      axios
        .get(
          this.getApiUrl +
            "/getGrades?applicant_id=" +
            this.$router.currentRoute.params.applicant_id,
          {
            headers: {
              locale: this.currentAppLocale,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          this.dataObject.grades = response.data.data.grades;
        });
    }

    axios
      .get(this.getApiUrl + "/getCountries", {
        headers: {
          locale: this.currentAppLocale,
          //the token is a variable which holds the token
        },
      })
      .then((response) => {
        this.dataObject.nationalities = response.data.data;
      });

    axios.get(this.getApiUrl + "/getCodes").then((response) => {
      this.dataObject.Codes = response.data.data;
    });
  },
};
